import { App } from '@capacitor/app'
import { AppLauncher } from '@capacitor/app-launcher'
import { BarcodeScanner } from '@capacitor-community/barcode-scanner'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { Dialog } from '@capacitor/dialog'
import { FCM } from '@capacitor-community/fcm'
import { InstallMode, codePush } from 'capacitor-codepush'
import { Keyboard } from '@capacitor/keyboard'
import { PushNotifications } from '@capacitor/push-notifications'
import { SplashScreen } from '@capacitor/splash-screen'
import { matchPath } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import semver from 'semver'

import { actions, useSelector } from '@/redux'
import { deploymentKeysToDeploymentMap, getDeployment, statusMessages } from '@/constants/codePush'
import { getPaymentTypeByReturnUrl } from '@/libs/payments'
import { isPoonChoiCategoryTag } from '@/libs/poonchoi'
import { remoteConfig } from '@/libs/firebase'
import StorageKey from '@/constants/storageKey'
import URLParser from '@/libs/URLParser'
import config from '@/config'
import constants from '@/constants'
import customerApi from '@/libs/api/customer'
import dimorderApi from '@/libs/api/dimorder'
import history from '@/libs/history'
import i18n from '@/i18n'
import landingApi from '@/libs/api/landing'
import logger, { flush, logId } from '@/libs/logger'
import openBrowser from '@/libs/openBrowser'
import timer from '@/libs/timer'
import vConsole from '@/libs/vConsole'

import { initialParams } from './reducer'
import { useMerchant } from '@root/src/hooks/merchant'
import ActionTypes from './ActionTypes'
import PaymentGateway from '@/constants/paymentGateway'

/** @typedef {import('@/redux/app/AppState.d').IAlertConfig} IAlertConfig */
/** @typedef {import('@/redux/app/AppState.d').IDatetime} IDatetime */
/** @typedef {import('@/redux/app/AppState.d').IDialog} IDialog */
/** @typedef {import('@/redux/app/AppState.d').IDrawer} IDrawer */
/** @typedef {import('@/redux/app/AppState.d').IModal} IModal */
/** @typedef {import('@/redux/app/AppState.d').IParams} IParams */
/** @typedef {import('@/redux/app/AppState.d').IPopover} IPopover */
/** @typedef {import('@/redux/app/AppState.d').ISnackbar} ISnackbar */
/** @typedef {import('dimorder-orderapp-lib/dist/types/PromoCode').IMarketingAction}  IMarketingAction */

const { TAKEAWAY, STORE_DELIVERY, TABLE } = constants.deliveryType
const { CREDIT_CARD, PAY_ME } = constants.paymentMethod

/**
 * 檢查 merchant.setting.dineInForceUsingApp 若強制使用 App 內用則前往 app.dimorder.com
 * @returns {ThunkFunction}
 */
export function checkDineInForceUsingAppAndRedirect () {
  return (dispatch, getState) => {
    const params = getState().app.params
    const dineInForceUsingApp = getState().merchant.data?.setting?.dineInForceUsingApp

    if (Capacitor.isNativePlatform()) return false // 已經在 App 不需要 redirect
    if (params.deliveryType !== TABLE) return false // 非堂食
    if (navigator.userAgent.includes('Android')) return false // Android App 很卡，不特別推薦使用
    if (!dineInForceUsingApp) return false // ! 沒限使用 App

    // 準備把 params 帶入 app
    const newParams = {
      init: true,
      appOnly: true,
    }
    for (const key in params) {
      // datetime 和 undefined 的值不用帶過去
      if (params[key] != null && key !== 'datetime') {
        newParams[key] = params[key]
      }
    }
    const searchParams = new URLSearchParams(newParams)
    window.location.href = `${config.universalBaseUrl}?${searchParams.toString()}`
    return true
  }
}

/**
 * 檢查 merchant.setting.dineInPayOnAppOnly 若堂食強制使用 App 付款則前往 app.dimorder.com/pay
 * @returns {ThunkFunction}
 */
export function checkDineInPayOnAppOnlyAndRedirect () {
  return (dispatch, getState) => {
    const params = getState().app.params
    const dineInPayOnAppOnly = getState().merchant.data?.setting?.dineInPayOnAppOnly

    if (Capacitor.isNativePlatform()) return false // 已經在 App 不需要 redirect
    if (params.deliveryType !== TABLE) return false // 非堂食
    if (navigator.userAgent.includes('Android')) return false // Android App 很卡，不特別推薦使用
    if (!dineInPayOnAppOnly) return false // ! 沒限制堂食只能用 App 付款

    // 準備把 params 帶入 app
    const newParams = {
      appOnly: true,
    }
    for (const key in params) {
      // datetime 和 undefined 的值不用帶過去
      if (params[key] != null && key !== 'datetime') {
        newParams[key] = params[key]
      }
    }
    const searchParams = new URLSearchParams(newParams)
    window.location.href = `${config.universalBaseUrl}?${searchParams.toString()}`
    return true
  }
}

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    timer.time('init')
    logger.log('[init][0/10] start')
    dispatch(updateLoading('backdrop', true))

    window.QRCodeScanUrl = (url) => {
      dispatch(handleQRCodeData(url))
    }

    timer.time('getDeviceInfo')
    await dispatch(getDeviceInfo())
    timer.timeEnd('getDeviceInfo')

    timer.time('checkAppUpdate')
    const isAppUpdate = await dispatch(checkAppUpdate())
    timer.timeEnd('checkAppUpdate')

    logger.log(`[init][1/10] checkAppUpdate Done. update: ${Boolean(isAppUpdate)}`)
    dispatch(codePushSync(false))
    logger.log('[init][2/10] codePushSync Done.')

    let initTimeout = null
    try {
      dispatch(updateLoading('backdrop', true))
      let clipboardUrl
      // FIXME: 剪貼簿沒東西時會 crash (plugin native bug)
      // try {
      //   const clipboardValue = await clipboardRead()
      //   if (URLParser.isValidSite(clipboardValue)) {
      //     clipboardUrl = clipboardValue
      //   }
      // } catch (error) {
      //   logger.error(`Clipboard.read() error ${error?.message || error}`, { error })
      // }
      // logger.log(`Clipboard ok, clipboardUrl ${clipboardUrl}`, { clipboardUrl })

      initTimeout = setTimeout(() => {
        SplashScreen.hide()
        logger.error('[init] error timeout')
        dispatch(toggleAlert({
          title: i18n.t('app.component.alert.init_failed.title'),
          message: i18n.t('app.component.alert.init_failed.message') + ` (${logId})`,
          dialogProps: { disableBackdropClick: true },
          button: {
            text: i18n.t('app.common.confirm'),
            onClick: () => {
              // 取得要保留的內容
              const deployment = localStorage.getItem('OVERWRITE_DEPLOYMENT')
              const address = localStorage.getItem('address')
              // clear location storage
              localStorage.clear()
              localStorage.setItem('OVERWRITE_DEPLOYMENT', deployment)
              localStorage.setItem('address', address)
            },
          },
        }))
      }, 20000)

      await dispatch(setKeyboardMode())
      logger.log('[init][3/10] setKeyboardMode() Done.')

      timer.time('startAppListener')
      await dispatch(startAppListener())
      timer.timeEnd('startAppListener')
      logger.log('[init][4/10] startAppListener() Done.')

      timer.time('getPaymentGateway')
      await dispatch(actions.app.getPaymentGateway())
      timer.timeEnd('getPaymentGateway')
      logger.log('[init][5/10] getPaymentGateway() Done.')

      timer.time('user.init')
      await dispatch(actions.user.init())
      timer.timeEnd('user.init')
      logger.log('[init][6/10] user.init() Done.')

      timer.time('orderHistory.init')
      await dispatch(actions.orderHistory.init())
      timer.timeEnd('orderHistory.init')
      logger.log('[init][7/10] orderHistory.init() Done.')

      timer.time('restoreParams and other')
      await dispatch(restoreParams(clipboardUrl))
      await dispatch(actions.payment.init())
      await dispatch(updateSystemDeliveryTypeEnable())
      timer.timeEnd('restoreParams and other')
      logger.log('[init][8/10] restoreParams() ... Done.')

      dispatch(updateLoading('backdrop', false))
      dispatch({ type: ActionTypes.INIT, payload: {} })

      SplashScreen.hide()
      logger.log('[init][9/10] complete, SplashScreen.hide()')

      // 若有 pendingMarketingAction 且 landing 已經 init，處理後移除
      const landingIsInit = getState().landing.isInit
      const pendingMarketingAction = getState().app.pendingMarketingAction
      if (landingIsInit && pendingMarketingAction) {
        dispatch(actions.app.handleMarketingAction(pendingMarketingAction.type, pendingMarketingAction.payload))
        dispatch(actions.app.removePendingMarketingAction())
      }
    } catch (error) {
      SplashScreen.hide()
      logger.error(`[init] error ${error?.message || error}`, { error })
      dispatch(toggleAlert({
        title: i18n.t('app.component.alert.init_failed.title'),
        message: i18n.t('app.component.alert.init_failed.message') + ` (${logId})`,
        dialogProps: { disableBackdropClick: true },
        button: {
          text: i18n.t('app.common.confirm'),
          onClick: async () => {
            // 取得要保留的內容
            const deployment = localStorage.getItem('OVERWRITE_DEPLOYMENT')
            const address = localStorage.getItem('address')
            // clear location storage
            localStorage.clear()
            localStorage.setItem('OVERWRITE_DEPLOYMENT', deployment)
            localStorage.setItem('address', address)

            // 將 gcp log 剩下還沒送出的 log 都送出才 reload
            await flush()
            // reload
            document.location.href = '/'
          },
        },
      }))
    } finally {
      clearTimeout(initTimeout)
      timer.timeEnd('init')
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function getDeviceInfo () {
  return async (dispatch, getState) => {
    if (!Capacitor.isNativePlatform()) {
      return
    }
    const appInfo = await App.getInfo()
    console.log('App.getInfo', appInfo)
    const { uuid: deviceId } = await Device.getId()
    console.log('Device.getId', deviceId)
    const deviceInfo = await Device.getInfo()
    console.log('Device.getInfo', deviceInfo)

    dispatch({
      type: ActionTypes.UPDATE_APP_INFO,
      payload: { appInfo },
    })
    dispatch({
      type: ActionTypes.UPDATE_DEVICE_INFO,
      payload: { deviceId, deviceInfo },
    })
  }
}

/**
 * CA-1283 檢查使用者的時區，若不在 +8 則禁止使用
 * @returns {ThunkFunction}
 */
export function checkTimeZone () {
  return async (dispatch, getState) => {
    const timezone = new Date().toString().match(/([A-Z]+[+-][0-9]+)/)[1]
    const isTimeZoneError = getState().app.isTimeZoneError
    if (
      timezone !== 'GMT+0800' &&
      !isTimeZoneError // 已經 isTimeZoneError 了，表示已經有處理過，不用再顯示一次 alert
    ) {
      // 設定一個 flag isTimeZoneError = true，避免 SplashAD 開啟
      dispatch({ type: ActionTypes.SET_TIME_ZONE_ERROR })
      // 若已經有顯示 Splash AD 先關閉
      dispatch(actions.app.toggleDialog('splashAd', false, {}))
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.component.alert.timezone_error.title', { timezone }),
        message: i18n.t('app.component.alert.timezone_error.message'),
        button: {
          text: i18n.t('app.component.alert.timezone_error.reload'),
          onClick: async () => {
            // 將 gcp log 剩下還沒送出的 log 都送出才 reload
            await flush()
            // reload
            document.location.href = '/'
          },
        },
        dialogProps: { disableBackdropClick: true },
      }))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function checkAppUpdate () {
  return async (dispatch, getState) => {
    try {
      if (!Capacitor.isNativePlatform()) {
        console.log('[CheckStoreUpdate] skip, not native')
        return false
      }

      const appVersion = getState().app.appInfo?.version
      const appBuild = getState().app.appInfo?.build
      if (!appVersion) {
        console.log('[CheckStoreUpdate] skip, no app version')
        return false
      }

      logger.log('[CheckStoreUpdate] checkAppUpdate start')

      const platform = Capacitor.getPlatform()
      await remoteConfig.fetchAndActivateWithTimeout()
      const minVersion = remoteConfig.getValue(`customer_app_min_version_${platform}`).asString()
      const latestVersion = remoteConfig.getValue(`customer_app_latest_version_${platform}`).asString()

      logger.log(`[CheckStoreUpdate] appVersion: ${appVersion}, minVersion: ${minVersion}, latestVersion: ${latestVersion}`, {
        appVersion,
        appBuild,
        minVersion,
        latestVersion,
      })

      const needUpdate = semver.lt(appVersion, minVersion)
      logger.log('[CheckStoreUpdate] app update needUpdate: ' + needUpdate)
      const askUpdate = semver.lt(appVersion, latestVersion)
      logger.log('[CheckStoreUpdate] app update askUpdate: ' + askUpdate)

      if (config.env !== 'prod') {
        console.log('[CheckStoreUpdate] skip, not prod env')
        return false
      }
      if (needUpdate) {
        // 無限 alert，不要讓他繼續 init app
        const showAlert = async () => {
          // TODO: i18n
          await Dialog.alert({
            title: 'APP 需要更新',
            message: '請在商店更新至最新版本',
            buttonTitle: '前往更新',
          })

          AppLauncher.openUrl({ url: config.storeUrl })
          return showAlert()
        }
        await showAlert()
        return true
      }

      if (askUpdate) {
        // TODO: i18n
        const result = await Dialog.confirm({
          title: 'APP 有新版本',
          message: '是否要前往更新？',
          okButtonTitle: '立即更新',
          cancelButtonTitle: '下次再說',
        })

        if (result.value) {
          AppLauncher.openUrl({ url: config.storeUrl })
        }
      }
      return false
    } catch (error) {
      logger.error(`[CheckStoreUpdate] checkAppUpdate error ${error.message || error}`, { error })
      return false
    }
  }
}

export function codePushSync (showUpdateDialog = false) {
  return async (dispatch, getState) => {
    // android 無法 codepush，web 沒有 codepush，只有 ios 要 sync
    if (Capacitor.getPlatform() !== 'ios') return
    timer.time('codePushSync')
    logger.log('[CodePush] codePushSync start.')

    try {
      timer.time('codePushSync/getCurrentPackage')
      const codePushPackageMeta = await codePush.getCurrentPackage()
      timer.timeEnd('codePushSync/getCurrentPackage')
      logger.log('[CodePush] getCurrentPackage', { codePushPackageMeta })
      if (codePushPackageMeta) {
        dispatch({
          type: ActionTypes.UPDATE_PACKAGE_META,
          payload: {
            codePushPackageMeta: {
              ...codePushPackageMeta,
              deployment: deploymentKeysToDeploymentMap[codePushPackageMeta.deploymentKey],
            },
          },
        })
      }
    } catch (error) {
      logger.warn(`[CodePush] getCurrentPackage error ${error.message || error}`)
    }

    try {
      logger.log(`[CodePush] sync with deployment: ${config.deployment} (${config.deploymentKey})`)
      timer.time('codePushSync/sync')

      const updateDialogOptions = {
        updateTitle: i18n.t('app.codepush.updateDialog.updateTitle'),
        mandatoryUpdateMessage: i18n.t('app.codepush.updateDialog.mandatoryUpdateMessage'),
        mandatoryContinueButtonLabel: i18n.t('app.codepush.updateDialog.mandatoryContinueButtonLabel'),
      }
      const status = await codePush.sync(
        {
          installMode: InstallMode.IMMEDIATE,
          deploymentKey: config.deploymentKey,
          updateDialog: updateDialogOptions,
        },
        (progress) => {
          const progressPercent = (100 * progress.receivedBytes / (progress.totalBytes || 1)).toFixed(0)
          logger.log(`[CodePush] download progress: ${progressPercent}% (${progress.receivedBytes} / ${progress.totalBytes})`, { progress })
        },
      )
      timer.timeEnd('codePushSync/sync')
      logger.log(`[CodePush] codePushSync complete. sync status: ${status} ${statusMessages[status]}`)
      if (showUpdateDialog) {
        dispatch(actions.snackbar.enqueueSnackbar({
          message: statusMessages[status],
        }))
      }
    } catch (error) {
      logger.error(`[CodePush] codePushSync error ${error.message || error}`, { error })
    } finally {
      timer.timeEnd('codePushSync')
    }
  }
}

/**
 * mode 不給的話預設 'native' （鍵盤不會覆蓋到畫面）
 * @param { 'body' | 'ionic' | 'native' | 'none' | undefined } mode
 * @returns {ThunkFunction}
 */
export function setKeyboardMode (mode) {
  return async (dispatch, getState) => {
    if (Capacitor.getPlatform() === 'ios') {
      // 'body' | 'ionic' | 'native' | 'none'
      // 文件沒寫是幹麻的
      // 測試起來 body = ionic = none 就是高度不會變，鍵盤蓋上面
      // native 是預設值，鍵盤出現時畫面會變小，導致 UI 可能會有狀況，所以目前先設 none
      await Keyboard.setResizeMode({
        // mode: 'none',
        mode: mode ?? 'native', // 改成 native 不然備註輸入框會被鍵盤蓋掉
      })
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function startAppListener () {
  return async (dispatch, getState) => {
    // * 以下皆為 Native 功能，非 Native 直接 return
    if (!Capacitor.isNativePlatform()) return

    App.addListener('appStateChange', (appState) => {
      console.log('appStateChange', appState)
      // 每次 App resumes 檢查更新
      if (appState.isActive) {
        dispatch(codePushSync(false))
      }
    })

    PushNotifications.addListener('pushNotificationReceived', notification => {
      logger.log('[pushNotificationReceived]', { notification })
      // APP 在前景時收到推播，目前沒有要處理
    })
    PushNotifications.addListener('pushNotificationActionPerformed', actionPerformed => {
      logger.log('[pushNotificationActionPerformed]', { actionPerformed })

      // 推播被點開時
      if (actionPerformed.notification?.data?.type === 'ACTION') {
        const action = JSON.parse(actionPerformed.notification?.data?.payload ?? null)

        // app init 過程中可能會 redirect，因此要等 app init 完成後再處理 marketing action
        // marketing action 很多都需要 landing 的 google sheet 資料，若 landing 還沒完成 init，先將 marketingAction pending，待 landing.init 完成後再處理
        const appIsInit = getState().app.isInit
        const landingIsInit = getState().landing.isInit
        if (appIsInit && landingIsInit) {
          dispatch(handleMarketingAction(action?.type, action?.payload))
        } else {
          dispatch(addPendingMarketingAction(action))
        }
      }
    })

    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        dispatch(actions.orderHistory.startOrdersUpdater())
      } else {
        dispatch(actions.orderHistory.stopOrdersUpdater())
      }
    })

    App.addListener('appUrlOpen', async (data) => {
      if (config.env !== 'prod') {
        dispatch(actions.snackbar.enqueueSnackbar({
          message: `appUrlOpen: ${JSON.stringify(data)}`,
        }))
      }

      // 若是從第三方付款回來 APP 的從 url 判斷是哪種付款方式
      const url = new URL(data.url)
      const paymentType = getPaymentTypeByReturnUrl(url)

      logger.log(`appUrlOpen ${data.url}`, { url: data.url, paymentType })

      if ([
        constants.paymentMethod.PAY_ME,
        constants.paymentMethod.WECHAT_PAY,
        constants.paymentMethod.ALI_PAY,
        constants.paymentMethod.FPS,
        constants.paymentMethod.OCTOPUS,
      ].includes(paymentType)) {
        // 若是從第三方付款回來，因為不需要 redirect，不要 restoreParams
        return
      }

      if (URLParser.isValidSite(data.url)) {
        dispatch(restoreParams(data.url))
      }
    })

    // 禁止用 Back button 回上頁的頁面
    const disableBackUrls = [
      '/',
      '/login',
    ]
    // Back button 需要做特別處理的頁面
    const backHandlerConfigs = [
      // {
      //   url: '/',
      //   checkState: () => {},
      //   handler: () => {},
      // },
    ]

    App.addListener('backButton', () => {
      const alerts = getState().app.alerts
      if (_.find(alerts, alert => alert.open)) {
        // 有打開的 alert 就全部關掉
        dispatch({
          type: ActionTypes.RESET_ALERTS,
        })
        return
      }
      const dialogs = getState().app.dialogs
      if (_.find(dialogs, (dialog) => dialog.open)) {
        // 有打開的 dialog 就全部關掉
        dispatch({
          type: ActionTypes.RESET_DIALOGS,
        })
        return
      }
      const drawers = getState().app.drawers
      if (_.find(drawers, (drawer) => drawer.open)) {
        // 有打開的 drawer 就全部關掉
        dispatch({
          type: ActionTypes.RESET_DRAWERS,
        })
        return
      }

      const url = history.location.pathname

      const handlerConfig = backHandlerConfigs.find(backHandlerConfig => {
        return backHandlerConfig.url === url && backHandlerConfig.checkState()
      })
      if (handlerConfig) {
        handlerConfig.handler()
        return
      }
      if (!disableBackUrls.includes(url)) {
        history.goBack()
      }
    })
  }
}

export const initPushNotification = () => {
  return async (dispatch, getState) => {
    // only use in native app
    if (!Capacitor.isNativePlatform()) return
    logger.log('[initPushNotification] start')

    try {
      let permissionStatus = await PushNotifications.requestPermissions()
      if (permissionStatus.receive === 'prompt') {
        permissionStatus = await PushNotifications.requestPermissions()
      }
      if (permissionStatus.receive !== 'granted') {
        logger.log('[initPushNotification] permission not granted, skip')
        return
      }
    } catch (error) {
      logger.error(`[initPushNotification] requestPermissions() error ${error?.message || error}`, { error })
    }

    PushNotifications.addListener('registration', async (registrationResult) => {
      logger.log('[initPushNotification] registration listener called', { registrationResult })
      try {
        let token = registrationResult.value
        if (Capacitor.getPlatform() === 'ios') {
          logger.log('[initPushNotification] ios FCM.getToken()')
          const result = await FCM.getToken()
          logger.log('[initPushNotification] ios FCM.getToken() result', { result })
          token = result.token
        }
        if (token) {
          const { uuid: deviceId } = await Device.getId()
          logger.log('[initPushNotification] registerFcmToken()', { deviceId, token })
          await customerApi.registerFcmToken(deviceId, token)
        }
      } catch (error) {
        logger.error(`[initPushNotification] registration error ${error?.message || error}`, { error })
      }
    })

    // registers device on FCM server
    logger.log('[initPushNotification] register()')
    await PushNotifications.register()
  }
}

/**
 * @returns {ThunkFunction}
 */
export function stopAppListener () {
  return (dispatch) => {
    console.log('stopAppListener()')
    App.removeAllListeners()
    dispatch(actions.orderHistory.stopOrdersUpdater())

    if (!Capacitor.isNativePlatform()) return
    PushNotifications.removeAllListeners()
  }
}

/**
 * 檢查餐廳有沒有開，如果沒開的話顯示 preorder drawer
 * @returns {ThunkFunction}
 */
export function openPreorderDrawer () {
  return async (dispatch, getState) => {
    const merchant = getState().merchant.data
    const categoryTag = getState().app.params.categoryTag
    const datetime = getState().app.params.datetime
    const deliveryType = getState().app.params.deliveryType
    const isPoonchoi = isPoonChoiCategoryTag(categoryTag)
    const datetimeDrawerOpen = getState().app.drawers.datetime.open

    if (isPoonchoi) return // 盆菜不顯示 preorder
    if (datetimeDrawerOpen) return // 已經開啟選時間的 drawer，不需要在開啟預約按鈕的 drawer

    // 非營業時間打開 preorder drawer
    const restaurant = getState().landing.restaurant

    // 外帶外送 createShipping 之後會檢查 datetime available，若不可用會嘗試使用即時 timeslot 若沒有即時 timeslot或還是不可用就會將 datetime.date, datetime.time 改成 undefined
    // 因此外帶外送沒有 datetime.time 表是需要打開 preorder drawer
    // 另外當 /landing 抓不到餐廳也開啟 preorder drawer
    if ((deliveryType !== TABLE && !datetime.time) || !restaurant) {
      logger.log('[openPreorderDrawer] datetime unavailable', { restaurant, merchant, datetime })
      dispatch(actions.app.toggleDrawer('preorder', true))
    }
  }
}

/**
 * @param {String} merchantId
 * @returns {ThunkFunction}
 */
export function restaurantInit (merchantId) {
  return async (dispatch, getState) => {
    const params = getState().app.params
    const appLang = getState().app.lang
    const selectedOrder = getState().order.selectedOrder

    logger.log('[restaurantInit][0/6] restaurantInit() Start.', { merchantId, params, selectedOrder })
    timer.time('restaurantInit')

    if (!merchantId) {
      // 如果沒有給 merchantId，跳過
      logger.warn('[restaurantInit] error no merchantId, skip restaurantInit')
      return
    }

    dispatch(actions.app.updateLoading('restaurantInit', true))

    // dispatch(actions.app.updateLoading('backdrop', true))
    dispatch(actions.merchant.reset())
    dispatch(actions.order.updateShipping(undefined))

    try {
      if (merchantId !== params.merchantId) {
        const updatedParams = {
          ...params,
          merchantId: merchantId, // 將 merchantId 存入 params
          orderId: undefined, // 清除 orderId
          table: undefined, // 清除 table
        }

        if (params.reloadGateway) {
          updatedParams.reloadGateway = false

          // reset merchant payment gateway
          await dispatch(actions.app.getPaymentGateway())
        }

        // 選擇的餐廳和 params 中還原的餐廳不同
        dispatch(updateParams(updatedParams))

        // 清除 batch
        dispatch(actions.orderBatch.resetBatch())
      }

      // 強制使用fiserv
      if ([
        'f82ece71-c09e-43fa-88eb-48ab1d20b7fd',
      ].includes(merchantId)) {
        dispatch(updateParams({
          ...params,
          reloadGateway: true,
        }))

        // reset merchant payment gateway
        dispatch({
          type: ActionTypes.UPDATE_PAYMENT_GATEWAY,
          payload: {
            paymentMethod: CREDIT_CARD,
            gateway: PaymentGateway.PAYMENT_GATEWAY_FISERV,
          },
        })
      }
      // 強制使用2c2p
      if ([
        'f50eb603-1001-4f24-8478-cc1be227ca88',
        '62ea1582-86a2-4a39-80a0-5acc581c3bdb',
        '606af954-6e5c-4b17-acb1-b86f689f5c73',
        'b43dee61-f7b6-4420-8171-ec36ffdd4b6f',
        '80355d1e-8cc7-4db2-afd7-7ec8b1988480',
      ].includes(merchantId)) {
        dispatch(updateParams({
          ...params,
          reloadGateway: true,
        }))

        // reset merchant payment gateway
        dispatch({
          type: ActionTypes.UPDATE_PAYMENT_GATEWAY,
          payload: {
            paymentMethod: CREDIT_CARD,
            gateway: PaymentGateway.PAYMENT_GATEWAY_2C2P,
          },
        })
      }

      await Promise.all([
        dispatch(actions.landing.init()),
        dispatch(actions.merchant.fetchMerchant(merchantId)),
        dispatch(actions.merchant.findOfflineDevice(merchantId, true))
          .then(hasOfflineDevice => {
            dispatch(actions.app.toggleMaterDeviceOfflineAlert(hasOfflineDevice))
          }),
      ])
      logger.log('[restaurantInit][2/6] fetchMerchant() Done.')

      if (params.isD2CWeb) {
        const clientLocales = getState().merchant.data.clientLocales
        const defaultClientLanguage = getState().merchant.data.setting.defaultClientLanguage

        // 根據 merchant 設定的用戶介面語言判斷是否需要更改目前的語言，D2C 中只能選擇 clientLocales 有設定的語言
        if (clientLocales.length > 0 && !clientLocales?.some(clientLocale => {
          return appLang?.startsWith(clientLocale)
        })) {
          // 當目前選擇的語言 (app.lang) 不在 merchant 設定的 clientLocales 中，這邊要幫他選擇預設的語言
          // clientLocale 裡面是 en 或 zh 這種短的語言，這邊要轉成 en-US 或 zh-TW 等
          const langMap = {
            en: 'en-US',
            zh: 'zh-HK',
            'zh-TW': 'zh-TW',
            ja: 'ja',
          }
          const defaultLang = langMap[defaultClientLanguage] ?? 'zh-HK'
          dispatch(actions.app.updateLang(defaultLang))
        }
      }
      // 在會員卡頁面不用 order.init
      const d2cGroupMatch = matchPath(window.location.pathname, { path: '/d2c/:merchantId/membership/:groupId' })
      const groupMatch = matchPath(window.location.pathname, { path: '/membership/:groupId' })
      const isInMembershipPage = d2cGroupMatch || groupMatch
      if (!isInMembershipPage && (!selectedOrder || selectedOrder.merchantId !== merchantId)) {
        await dispatch(actions.order.init())
        logger.log('[restaurantInit][3/6] order.init() Done.')
      } else {
        logger.log('[restaurantInit][3/6] order.init() Skip.')
      }

      await dispatch(actions.order.createShipping())
      logger.log('[restaurantInit][4/6] createShipping() Done.')

      if (!params.isD2CWeb) {
        // 判斷是否為非營業時間來打開 preorder drawer
        await dispatch(openPreorderDrawer(merchantId))
      }

      // 選擇預設付款方式
      dispatch(actions.payment.selectDefaultPayment())
      logger.log('[restaurantInit][6/6] selectDefaultPayment() Done.')

      dispatch(actions.app.updateLoading('restaurantInit', false))
    } catch (error) {
      logger.log(`[restaurantInit] error ${error?.message || error}`, { error })
      if (!params.isD2CWeb) {
        // !d2c error handling
        if (error?.response?.status === 404) {
        // 找不到餐廳，回到 restaurant list
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.restaurant_not_found.title'),
            message: i18n.t('app.component.alert.restaurant_not_found.message'),
            dialogProps: { disableBackdropClick: true },
            button: {
              text: i18n.t('app.common.back'),
              onClick: () => {
                history.replace('/restaurants')
              },
            },
          }))
        } else {
        // 其他錯誤，顯示餐廳初始失敗，回到餐廳列表
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.restaurant_init_failed.title'),
            message: i18n.t('app.component.alert.restaurant_init_failed.message'),
            dialogProps: { disableBackdropClick: true },
            button: {
              text: i18n.t('app.common.back'),
              onClick: () => {
                history.replace('/restaurants')
              },
            },
          }))
        }
      } else {
        // d2c error handling
        if (error?.response?.status === 404) {
        // 找不到餐廳，停在原地
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.restaurant_not_found.title'),
            dialogProps: { disableBackdropClick: true },
            buttons: [],
          }))
        } else {
          // 其他錯誤，顯示餐廳初始失敗，重新載入
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.restaurant_init_failed.title'),
            dialogProps: { disableBackdropClick: true },
            button: {
              text: i18n.t('app.common.back'),
              onClick: () => {
                history.go(0)
              },
            },
          }))
        }
      }
    } finally {
      dispatch(actions.app.updateLoading('backdrop', false))
      timer.timeEnd('restaurantInit')
    }
  }
}

/**
 * @param {string} loading
 * @param {boolean} open
 * @returns {ThunkFunction}
 */
export function updateLoading (loading, open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_LOADING,
      payload: { loading, open },
    })
  }
}

/**
 * @param {passcode} passcode
 * @returns {ThunkFunction}
 */
export function loginSetting () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_IS_LOGIN_SETTING,
      payload: { isLoginSetting: true },
    })
  }
}

/**
 * 僅更改 api 語言、時間顯示語言、UI 語言，若需要讓菜單和訂單內容套用新的語言需要重新 fetchCategories, getOrders
 * @param {string?} lang
 * @returns {ThunkFunction}
 */
export function updateLang (lang) {
  return async (dispatch, getState) => {
    const isD2CWeb = getState().app.params.isD2CWeb
    const broswerLang = navigator.language || navigator.userLanguage

    const i18nextLng = lang != null ? lang : broswerLang
    const apiLanguage = i18nextLng.slice(0, 2) // 'zh-TW' => 'zh'

    // update default language
    if (isD2CWeb) {
      localStorage.setItem(StorageKey.D2C_MODE_LANG, i18nextLng)
    } else {
      localStorage.setItem(StorageKey.LANG, i18nextLng)
    }

    // update api language
    dimorderApi.setLanguage(apiLanguage)
    landingApi.setLanguage(apiLanguage)

    // update i18n language
    i18n.changeLanguage(i18nextLng)

    // update moment language
    moment.locale(i18nextLng.toLocaleLowerCase())

    // update customer language
    const isLogin = Boolean(getState().user.member?.id)
    if (isLogin) {
      dispatch(actions.user.updateCustomerInfo({ locale: apiLanguage }))
    }

    // update redux state
    dispatch({
      type: ActionTypes.UPDATE_LANG,
      payload: { lang: i18nextLng },
    })
  }
}

/**
 * @param {IAlertConfig?} alertConfig
 * @param {string?} id
 * @param {boolean?} open
 * @returns {ThunkFunction}
 */
export function toggleAlert (alertConfig, id, open = true) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_ALERT,
      payload: {
        id: id || uuid(),
        open,
        alertConfig,
      },
    })
  }
}

/**
 * @param {keyof IDrawer} drawer
 * @param {boolean?} open
 * @param {IModal.data} data
 * @returns {ThunkFunction}
 */
export function toggleDrawer (drawer, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_DRAWER,
      payload: { drawer, open, data },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetDrawers () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DRAWERS,
    })
  }
}

/**
 * @param {keyof IDialog} dialog
 * @param {boolean?} open
 * @param {IModal.data} data
 * @returns {ThunkFunction}
 */
export function toggleDialog (dialog, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_DIALOG,
      payload: { dialog, open, data },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetDialogs () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DIALOGS,
    })
  }
}

/**
 * @param {keyof ISnackbar} snackbar
 * @param {boolean?} open
 * @param {IModal.data} data
 * @returns {ThunkFunction}
 */
export function toggleSnackbar (snackbar, open, data) {
  return (dispatch, getState) => {
    const isOpen = getState().app.snackbars.orderTracking
    if (open === isOpen) return
    dispatch({
      type: ActionTypes.TOGGLE_SNACKBAR,
      payload: { snackbar, open, data },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetSnackbar () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.REST_SNACKBAR,
    })
  }
}

/**
 * @param {keyof IPopover} popover
 * @param {boolean?} open
 * @param {IModal.data} data
 * @returns {ThunkFunction}
 */
export function togglePopover (popover, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_POPOVER,
      payload: { popover, open, data },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetPopover () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_POPOVER,
    })
  }
}

/**
 * @param {PropertyPath} path
 * @param {any} setting
 * @returns {ThunkFunction}
 */
export function updateSetting (path, setting) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SETTING,
      payload: { path, setting },
    })
  }
}

/**
 * @param {string?} url
 * @returns {ThunkFunction}
 */
export function restoreParams (url) {
  logger.log(`[restoreParams] url: ${url}`)
  return async (dispatch, getState) => {
    let storageParams = JSON.parse(localStorage.getItem('params')) ?? {}
    // CA-951，每次進來 APP 要重設 datetime，因此不還原 datetime
    delete storageParams.datetime
    let stateParams = getState().app.params

    let urlParser = null
    let urlParams = {}

    try {
      urlParser = await URLParser.create(url)
      urlParams = urlParser.getParams()
      logger.log('[restoreParams] urlParser', { urlParser, urlParams })
    } catch (error) {
      logger.error(`[restoreParams] URLParser error ${error?.message || error}`, { error })
    }

    if (urlParams.init) {
      // 網址有帶 init 時不還原任何 params
      stateParams = {}
      storageParams = {}
    }

    if (
      (urlParams.table && stateParams.table && urlParams.table !== stateParams.table) || // 網址有帶桌號 id，但與 redux 不一致，不還原 params
      (urlParams.orderId && stateParams.orderId && urlParams.orderId !== stateParams.orderId) // 網址有帶 order id，但與 redux 不一致，不還原 params
    ) {
      stateParams = {}
    }

    if (
      (urlParams.table && storageParams.table && urlParams.table !== storageParams.table) || // 網址有帶桌號 id，但與 localStorage 不一致，不還原 params
      (urlParams.orderId && storageParams.orderId && urlParams.orderId !== storageParams.orderId) // 網址有帶 order id，但與 localStorage 不一致，不還原 params
    ) {
      storageParams = {}
    }

    // redux 的餐廳和 url 相同才能還原
    const allowRestoreReduxState = !urlParser.merchantId || stateParams.merchantId === urlParser.merchantId
    if (!allowRestoreReduxState) {
      // 不允許還原，清除 storageParams
      stateParams = {}
    }

    if (stateParams.isD2CWeb && !urlParser.get('isD2CWeb')) {
      // state 是 d2c 但 url 不是 d2c 不允許還原，清除 stateParams
      stateParams = {}
    }

    // storage 的餐廳和 url 相同才能還原
    const allowRestoreLocalStorage = !urlParser.merchantId || storageParams.merchantId === urlParser.merchantId
    if (!allowRestoreLocalStorage) {
      // 不允許還原，清除 storageParams
      storageParams = {}
    }

    if (storageParams.isD2CWeb && !urlParser.get('isD2CWeb')) {
      // storage 是 d2c 但 url 不是 d2c 不允許還原，清除 storageParams
      storageParams = {}
    }

    if (!allowRestoreReduxState && !allowRestoreLocalStorage) {
      // 不允許還原，清除 localStorage 中的 batchStorage
      dispatch(actions.orderBatch.resetBatch())
    }

    const params = {
      ...initialParams,
      ...stateParams,
      ...storageParams,
      ...urlParams,
    }

    // init 用完就可以刪了，不用留在 params
    delete params.init
    urlParser.searchParams.delete('init')

    if (params.categoryTag === '') {
      delete params.categoryTag
      urlParser.searchParams.delete('categoryTag')
    }

    logger.log('[restoreParams] params', { params })
    dispatch(updateParams(params))

    vConsole.init()

    if (urlParams.lang) {
      // 網址中有指定語言
      const langMap = {
        en: 'en-US',
        zh: 'zh-HK',
        'zh-TW': 'zh-TW',
        ja: 'ja',
      }
      dispatch(updateLang(langMap[urlParams.lang] ?? urlParams.lang))

      // 語言已經存到 storage，不用留在網址，避免 user 更改語言後 refresh 又變回網址指定的語言
      delete urlParams.lang
      urlParser.searchParams.delete('lang')
    } else {
      // 沒指定就從 storage 還原根據是不是 D2C 模式，還原語言設定
      const i18nextLng = localStorage.getItem(params.isD2CWeb ? StorageKey.D2C_MODE_LANG : StorageKey.LANG)
      // 沒有抓到就不要 restore
      if (i18nextLng) {
        dispatch(updateLang(i18nextLng))
      }
    }

    if (params.isD2CWeb) {
      // 這兩個 params 網址已經有了，不用留著
      urlParser.searchParams.delete('merchantId')
      urlParser.searchParams.delete('isD2CWeb')
      const newSearchParams = urlParser.searchParams.toString()
      if (newSearchParams !== window.location.search.replace('?', '')) {
        history.replace(`/d2c/${params.merchantId}?${newSearchParams}`)
      }
    } else {
      const newSearchParams = urlParser.searchParams.toString()
      if (newSearchParams !== window.location.search.replace('?', '')) {
        history.replace(`/?${newSearchParams}`)
      }
    }

    if (params.promoCodeId) {
      // 如果有帶 promoCodeId，前往 coupon page
      if (params.isD2CWeb) {
        // 拿掉 promoCodeId，已經存到 redux 了
        urlParser.searchParams.delete('promoCodeId')
        history.push(`/d2c/${urlParser.merchantId}/member/coupon?${urlParser.searchParams.toString()}`)
      } else {
        history.push(`/member?${urlParser.searchParams.toString()}`)
        history.push(`/member/coupon?${urlParser.searchParams.toString()}`)
      }
      return
    }

    // 前往指定的網址
    if (history.location.pathname !== urlParser.url.pathname) {
      history.push(`${urlParser.url.pathname}?${urlParser.searchParams.toString()}`)
    }
  }
}

/**
 * store redux app.params to localstorage
 * @returns {ThunkFunction}
 */
export function storeParams () {
  return (dispatch, getState) => {
    const params = { ...getState().app.params }

    // CA-951，每次進來 APP 要重設 datetime，因此不存 datetime
    delete params.datetime

    localStorage.setItem('params', JSON.stringify(params))
  }
}

/**
 * @param {IParams} params
 * @returns {ThunkFunction}
 */
export function updateParams (params) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_PARAMS,
      payload: { params },
    })

    dispatch(storeParams())
  }
}

/**
 * @param {boolean} isD2CWeb
 * @returns {ThunkFunction}
 */
export function updateIsD2CWeb (isD2CWeb) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_IS_D2C_WEB,
      payload: { isD2CWeb },
    })

    dispatch(storeParams())
  }
}

/**
 * delete redeem code in params
 * @returns {ThunkFunction}
 */
export function deleteRedeemCode () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.DELETE_REDEEM_CODE,
    })

    dispatch(storeParams())
  }
}

/**
 * delete promoCodeId code in params
 * @returns {ThunkFunction}
 */
export function deletePromoCodeId () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.DELETE_PROMO_CODE_ID,
    })

    dispatch(storeParams())
  }
}

/**
 * @param {IDatetime} datetime
 * @returns {ThunkFunction}
 */
export function updateDatetime (datetime) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_DATETIME,
      payload: { datetime },
    })

    dispatch(storeParams())
  }
}

/**
 * @param {TDeliveryType} deliveryType
 * @returns {ThunkFunction}
 */
export function updateDeliveryType (deliveryType) {
  return (dispatch, getState) => {
    const prevDeliveryType = getState().app.params.deliveryType

    if (deliveryType !== prevDeliveryType) {
      dispatch({
        type: ActionTypes.UPDATE_DELIVERY_TYPE,
        payload: { deliveryType },
      })

      // 清除訂單
      dispatch(actions.order.resetOrder('updateDeliveryType'))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function exitDineIn () {
  return (dispatch, getState) => {
    const params = getState().app.params

    dispatch(updateParams({
      ...params,
      orderId: undefined,
      table: undefined,
    }))

    dispatch(actions.orderBatch.resetBatch())
    dispatch(actions.order.createLocalOrder())
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetOrderId () {
  return (dispatch, getState) => {
    const params = getState().app.params

    dispatch(updateParams({
      ...params,
      orderId: undefined,
    }))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetTable () {
  return (dispatch, getState) => {
    const params = getState().app.params

    dispatch(updateParams({
      ...params,
      table: undefined,
      orderId: undefined,
    }))
  }
}

/**
 * @param {string} table
 * @returns {ThunkFunction}
 */
export function updateTable (table) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_TABLE,
      payload: { table },
    })

    dispatch(storeParams())
  }
}

/**
 * @param {Boolean} open
 * @returns {ThunkFunction}
 */
export function openQRCodeScanningScreen (open) {
  // 掃描時需將 body 設為透明，因為 WebView 蓋在 Native 掃描器上面
  window.document.body.style.background = open ? 'transparent' : 'white'
  return {
    type: ActionTypes.UPDATE_SCANNING,
    payload: { isQRCodeScanning: open },
  }
}

/**
 * @returns {ThunkFunction}
 */
export function openQRCodeScanner () {
  return async (dispatch) => {
    if (!Capacitor.isNativePlatform()) {
      // open QrReaderDrawer
      dispatch(toggleDrawer('qrReader', true))
    } else {
      // open native scanner

      // check permission
      const status = await BarcodeScanner.checkPermission({ force: true })
      if (status.granted) {
        dispatch(openQRCodeScanningScreen(true))

        // start scanning and wait for a result
        const result = await BarcodeScanner.startScan()

        dispatch(openQRCodeScanningScreen(false))

        // if the result has content
        if (result.hasContent) {
          dispatch(handleQRCodeData(result.content))
        } else {
          dispatch(toggleAlert({
            title: i18n.t('app.component.alert.scan_qr.title'),
            message: i18n.t('app.component.alert.scan_qr.message'),
          }))
        }
        return
      }

      logger.log(`openQRCodeScanner camera permission granted: ${status.granted}`, { status })

      if (status.denied || status.neverAsked) {
        // not allow, ask again
        const confirmResult = await Dialog.confirm({
          title: i18n.t('app.component.alert.camera_not_accessible.title'),
          message: i18n.t('app.component.alert.camera_not_accessible.message'),
        })
        logger.log(`openQRCodeScanner camera permission confirm: ${confirmResult?.value}`, { confirmResult })
        if (confirmResult.value) {
          BarcodeScanner.openAppSettings()
        }
      }
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function closeQRCodeScanner () {
  return (dispatch, getState) => {
    if (Capacitor.getPlatform() === 'web') {
      const isOpened = getState().app.drawers.qrReader.open
      if (isOpened) {
        dispatch(toggleDrawer('qrReader', false))
      }
    } else {
      BarcodeScanner.stopScan()

      dispatch(openQRCodeScanningScreen(false))
    }
  }
}

/**
 * @param {string} data
 * @returns {ThunkFunction}
 */
export function handleQRCodeData (data) {
  return async (dispatch, getState) => {
    if (data.startsWith('APP=')) {
      const appMode = data.split('APP=')[1] === 'true'
      dispatch(updateDebugMode(appMode))
    }
    if (data.startsWith('DEBUG_MODE=')) {
      const debugMode = data.split('DEBUG_MODE=')[1] === 'true'
      dispatch(updateDebugMode(debugMode))
    }
    if (data.startsWith('OVERWRITE_ENV=')) {
      const changeEnv = data.split('OVERWRITE_ENV=')[1]
      dispatch(overwriteEnv(changeEnv))
    }
    if (data.startsWith('OVERWRITE_DEPLOYMENT=')) {
      const changeDeployment = data.split('OVERWRITE_DEPLOYMENT=')[1]
      dispatch(overwriteDeployment(changeDeployment))
    }
    if (URLParser.isValidSite(data)) {
      localStorage.setItem('FROM_IN_APP_QRCODE_SCAN', true)
      await dispatch(restoreParams(data))
      const d2cGroupMatch = matchPath(window.location.pathname, { path: '/d2c/:merchantId/membership/:groupId' })
      const groupMatch = matchPath(window.location.pathname, { path: '/membership/:groupId' })
      if (d2cGroupMatch || groupMatch) {
        // 在會員卡頁面不用 order.init
        return
      }
      dispatch(actions.order.init())
    }
  }
}

/**
 * @param {boolean} appMode
 * @returns {ThunkFunction}
 */
export function updateAppMode (appMode) {
  return async (dispatch, getState) => {
    console.log('updateAppMode', appMode)

    // clear params
    localStorage.removeItem('params')

    localStorage.setItem('APP', appMode)

    // 將 gcp log 剩下還沒送出的 log 都送出才 reload
    await flush()
    // reload
    document.location.href = '/'
  }
}

/**
 * @param {boolean} debugMode
 * @returns {ThunkFunction}
 */
export function updateDebugMode (debugMode) {
  return (dispatch, getState) => {
    console.log('updateDebugMode', debugMode)
    if (debugMode) {
      localStorage.setItem('DEBUG_MODE', debugMode)
      config.debugMode = true
    } else {
      // 取得要保留的內容
      const deployment = localStorage.getItem('OVERWRITE_DEPLOYMENT')
      const address = localStorage.getItem('address')

      // clear location storage
      localStorage.clear()
      localStorage.removeItem('DEBUG_MODE')
      localStorage.setItem('OVERWRITE_DEPLOYMENT', deployment)
      localStorage.setItem('address', address)
      config.debugMode = false
    }

    timer.option.disabled = !debugMode
  }
}

/**
 * @param {TEnv} env
 * @returns {ThunkFunction}
 */
export function overwriteEnv (env) {
  return async (dispatch, getState) => {
    console.log('overwriteEnv', env)
    if (config.env !== env) {
      // 取得要保留的內容
      const deployment = localStorage.getItem('OVERWRITE_DEPLOYMENT')
      const address = localStorage.getItem('address')

      // clear location storage
      localStorage.clear()

      localStorage.setItem('DEBUG_MODE', true)
      localStorage.setItem('OVERWRITE_ENV', env)
      localStorage.setItem('OVERWRITE_DEPLOYMENT', deployment)
      localStorage.setItem('address', address)

      // 將 gcp log 剩下還沒送出的 log 都送出才 reload
      await flush()
      // reload
      document.location.href = '/'
    }
  }
}

/**
 * @param {TDeployment} deployment
 * @returns {ThunkFunction}
 */
export function overwriteDeployment (deployment) {
  return (dispatch, getState) => {
    console.log('overwriteDeployment', deployment)
    if (deployment != null) {
      localStorage.setItem('DEBUG_MODE', true)
      localStorage.setItem('OVERWRITE_DEPLOYMENT', deployment)
    } else {
      localStorage.removeItem('OVERWRITE_DEPLOYMENT')
    }

    const [newDeployment, newDeploymentKey] = getDeployment(config.env)
    config.deployment = newDeployment
    config.deploymentKey = newDeploymentKey

    if (newDeployment !== getState().app.codePushPackageMeta?.deployment) {
      dispatch(codePushSync(true))
    }
  }
}

/**
 * @param {boolean} isSticky
 * @returns {ThunkFunction}
 */
export function updateCategoryBarSticky (isSticky) {
  return (dispatch, getState) => {
    const currentSticky = getState().app.isCategoryBarSticky
    if (currentSticky === isSticky) return
    dispatch({
      type: ActionTypes.UPDATE_CATEGORYBAR_STICKY,
      payload: { isSticky },
    })
  }
}

/**
 * @param {number} height
 * @returns {ThunkFunction}
 */
export function updateSafeAreaHeight (height) {
  return (dispatch, getState) => {
    const currentHeight = getState().app.safeAreaHeight
    if (height !== currentHeight) {
      dispatch({
        type: ActionTypes.UPDATE_SAFEAREA_HEIGHT,
        payload: { height },
      })
    }
  }
}

/**
 * @param {boolean} open
 * @returns {ThunkFunction}
 */
export function toggleLoginDrawer (open) {
  return (dispatch, getState) => {
    if (!_.isBoolean(open)) return
    if (open) {
      dispatch(toggleDrawer('login', true))
      dispatch(actions.app.setKeyboardMode('none'))
    } else {
      dispatch(toggleDrawer('login', false))
      dispatch(actions.app.setKeyboardMode('native'))
    }
  }
}

export function getPaymentGateway (merchantId) {
  return async (dispatch) => {
    const paymeGatewayResponse = await axios.get(config.api.dimorderNode + '/c/system/payme_gateway')
    const creditCardGatewayResponse = await axios.get(config.api.dimorderNode + '/c/system/credit_card_gateway')
    const payMeGateway = _.get(paymeGatewayResponse, 'data.gateway', PaymentGateway.PAYMENT_GATEWAY_2C2P)
    const creditCardGateway = _.get(creditCardGatewayResponse, 'data.gateway', PaymentGateway.PAYMENT_GATEWAY_FISERV)
    logger.log(`[getPaymentGateway] payme: ${payMeGateway}, creditCard: ${creditCardGateway}`)
    dispatch({
      type: ActionTypes.UPDATE_PAYMENT_GATEWAY,
      payload: { paymentMethod: PAY_ME, gateway: payMeGateway },
    })
    dispatch({
      type: ActionTypes.UPDATE_PAYMENT_GATEWAY,
      payload: { paymentMethod: CREDIT_CARD, gateway: creditCardGateway },
    })
  }
}

/**
 * 檢查外賣外送是否在 admin setting 裡關掉
 * @returns {ThunkFunction}
 */
export function updateSystemDeliveryTypeEnable () {
  return async (dispatch, getState) => {
    const baseUrl = config.api.dimorderNode
    const {
      isD2CWeb,
      takeaway: isD2CTakeawayEnabled,
      storeDelivery: isD2CStoreDeliveryEnabled,
    } = getState().app.params

    try {
      const [takeawayAdminSettingResponse, storeDeliveryAdminSettingResponse] = await Promise.all([
        axios.get(baseUrl + '/c/system/takeaway'),
        axios.get(baseUrl + '/c/system/storedelivery'),
      ])
      const takeawayAdminSetting = takeawayAdminSettingResponse?.data
      const storeDeliveryAdminSetting = storeDeliveryAdminSettingResponse?.data

      if (!takeawayAdminSetting.enable && (!isD2CWeb || isD2CTakeawayEnabled)) {
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.system_takeaway_disable.title'),
          messages: [
            i18n.t('app.component.alert.system_takeaway_disable.message'),
            takeawayAdminSetting.remark,
          ],
        }))
      }

      if (!storeDeliveryAdminSetting.enable && (!isD2CWeb || isD2CStoreDeliveryEnabled)) {
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.system_storeDelivery_disable.title'),
          messages: [
            i18n.t('app.component.alert.system_storeDelivery_disable.message'),
            storeDeliveryAdminSetting.remark,
          ],
        }))
      }

      dispatch({
        type: ActionTypes.UPDATE_SYSTEM_DELIVERYTYPE_ENABLE,
        payload: {
          deliveryType: TAKEAWAY,
          systemEnable: {
            ...takeawayAdminSetting,
            enable: Boolean(takeawayAdminSetting.enable),
          },
        },
      })

      dispatch({
        type: ActionTypes.UPDATE_SYSTEM_DELIVERYTYPE_ENABLE,
        payload: {
          deliveryType: STORE_DELIVERY,
          systemEnable: {
            ...storeDeliveryAdminSetting,
            enable: Boolean(storeDeliveryAdminSetting.enable),
          },
        },
      })
    } catch (error) {
      console.log('updateSystemDeliveryTypeEnable error', error)
    }
  }
}

/**
 * @param {string} path
 * @param {any} value
 * @returns {ThunkFunction}
 */
export function updateTest (path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_TEST,
      payload: { path, value },
    })
  }
}

/**
 *
 * @param {IMarketingAction} action
 * @param {any} payload
 * @returns {ThunkFunction}
 */
export function handleMarketingAction (action, payload) {
  return (dispatch, getState) => {
    logger.log('[handleMarketingAction]', { action, payload })

    // 沒有 payload 不動作
    if (!payload || payload === '') return

    switch (action) {
      case 'MERCHANT': {
        const merchantId = payload
        history.push('/restaurant/' + merchantId)
        break
      }

      case 'CATEGORY': {
        const categoryName = payload
        dispatch(actions.landing.redirectToCategory(categoryName))
        break
      }

      case 'CUISINE': {
        const cuisineName = payload
        dispatch(actions.landing.redirectToCuisine(cuisineName))
        break
      }

      case 'SEARCH': {
        const searchText = payload
        dispatch(actions.landing.updateSearchText(searchText))
        history.push('/search')
        break
      }

      case 'LINK': {
        let url = payload
        if (!url.startsWith('http')) {
          url = 'https://' + url
        }
        openBrowser(url)
        break
      }

      default:
        break
    }
  }
}

/**
 * 當 landing 尚未完成初始設定，先將 marketingAction 暫存，待 landing.init 完成後再處理
 * @param {IMarketingAction} marketingAction
 */
export function addPendingMarketingAction (marketingAction) {
  logger.log('[addPendingMarketingAction]', { marketingAction })
  return {
    type: ActionTypes.ADD_PENDING_MARKETING_ACTION,
    payload: marketingAction,
  }
}

/**
 * 移除完成的 pendingMarketingAction
 */
export function removePendingMarketingAction () {
  logger.log('[removePendingMarketingAction]')
  return {
    type: ActionTypes.REMOVE_PENDING_MARKETING_ACTION,
  }
}

/**
 *
 * @param {boolean} hasOfflineDevice
 * @returns {ThunkFunction}
 */
export function toggleMaterDeviceOfflineAlert (hasOfflineDevice) {
  return (dispatch, getState) => {
    if (hasOfflineDevice) {
      dispatch(toggleAlert({
        title: i18n.t('app.component.alert.merchant_device_offline.title'),
        message: i18n.t('app.component.alert.merchant_device_offline.message'),
        button: {
          text: i18n.t('app.common.back'),
        },
      }))
    }
  }
}

/**
 * 清除 params 和 url 中的 p 和 orderId (領取積分用的 password)
 * @param {string} password
 * @returns {ThunkFunction}
 */
export function removeClaimCRMPointsPassword () {
  return (dispatch, getState) => {
    const params = getState().app.params

    // 移除 p (領取積分用的 password) 和 orderId
    const newParams = { ...params }
    delete newParams.p
    delete newParams.orderId

    // 從 url 中移除 p (領取積分用的 password) 和 orderId
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('p')
    searchParams.delete('orderId')

    dispatch(updateParams(newParams))
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.replaceState(null, '', newUrl)
  }
}
