/** @type {IBaseConfig} */
const defaultConfig = {
  api: {
    dimorderGo: 'https://test-api.dimorder.com',
    dimorderNode: 'https://test-node.dimorder.com',
    customer: 'https://test-customer-api.dimorder.com',
    landing: 'https://test-node.dimorder.com',
    lalamovePrice: 'https://test-delivery-price.dimorder.com',
    dimbox: 'https://box-api.dimorder.com',
  },
  stripe: {
    apiKey: 'pk_test_4JjtUXuO4J0qdM3IfV5RsX1f00dPhv17jN',
  },
  firebase: {
    apiKey: 'AIzaSyAQef--6OoYEJs1OgntDq6LiDPgEEjbHug',
  },
  fps: {
    baseUrl: 'https://fps.dimorder.com/c/payment/fps',
    androidAction: 'hk.com.hkicl',
  },
  checkout: {
    publicKey: 'pk_test_8535819b-6321-481e-8823-428b20d5d93e',
    visaPublicKey: 'pk_test_8535819b-6321-481e-8823-428b20d5d93e',
  },
  webUrl: 'https://test-c.dimorder.com',
  universalBaseUrl: 'https://test-app.dimorder.com',
  receiptUrl: 'https://test-receipt.dimorder.com',
  links: {
    tnc: 'https://www.dimorder.com/tnc/',
    privacy: 'https://www.dimorder.com/privacy-policy/',
  },
  my2c2p: {
    jsSrcSecureField: '/my2c2p-secureFields.1.0.0.min.js',
    jsSrcSecurePayment: '/my2c2p-sandbox.1.7.3.min.js',
    jsSrcSecureFieldLive: '/my2c2p-secureFields.1.0.0.min.js',
    jsSrcSecurePaymentLive: '/my2c2p.1.7.3.min.js',
  },
  applePay2c2p: {
    merchantId: 'merchant.com.dimorder.customer',
    merchantDomain: 'test-c.dimorder.com',
  },
  fiserv: {
    connectUrl: 'https://test.ipg-online.com/connect/gateway/processing',
    sharedSecret: 'sa9i7bDYCT',
    storeId: '4700001244',
    dateTimeFormat: 'YYYY:MM:DD-HH:mm:ss',
    defaultData: {
      timezone: 'Asia/Hong_Kong',
      hash_algorithm: 'HMACSHA256',
      currency: '344',
      checkoutoption: 'combinedpage',
      transactionNotificationURL: 'https://test-api.dimorder.com/s/hook/fiserv',
    },
  },
}

export default defaultConfig
