import { Capacitor } from '@capacitor/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'

import { Provider as BatchItemQuantityContextProvider } from '@/hooks/contexts/BatchItemQuantityContext'
import { actions, useDispatch, useSelector } from '@/redux'
import constants from '@/constants'
import libs from '@/libs'

import { useCart } from '@/hooks/useCart'
import { useDeliveryType, useParams } from '@/hooks/app'
import { useIsLogin } from '@/hooks/user'
import { useMerchant } from '@/hooks/merchant'
import { useOnStatusTapToPageTop } from '@/hooks/useOnStatusTap'
import { useSelectedBatch, useSelectedOrder } from '@/hooks/order'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'
import useTrack from '@/hooks/useTrack'

import BackdropLoading from '@/components/BackdropLoading'
import CartFooter from '@/components/Footers/CartFooter'
import CheckoutFooter from '@/components/Footers/CheckoutFooter'
import DonateRiceCoinCard from '@/components/Cards/DonateRiceCoinCard'
import Navbar from '@/components/Navbar'
import NoticeCard from '@/components/Cards/NoticeCard'
import OrderBatchItemList from '@/components/OrderBatchItemList'
import OrderRewardItemList from '@/components/OrderRewardItemList'
import Page from '@/components/Page'
import PageContainer from '@/components/Page/PageContainer'
import PaymentCard from '@/components/Cards/PaymentCard'
import PaymentMethodDrawer from '@/components/Drawers/PaymentMethodDrawer'
import PaymentSummaryCard from '@/components/Cards/PaymentSummaryCard'
import RecommendedMenuList from '@/components/RecommendedMenuList'
import RedeemRiceCoinCard from '@/components/Cards/RedeemRiceCoinCard'

import InfoCard from './InfoCard'
import TermsAndConditions from './TermsAndConditions'

import logger from '@/libs/logger'

/**
 * @typedef CheckoutProps
 * @property {boolean} [isCard]
 */

/**
 *
 * @param {CheckoutProps} props
 * @returns
 */
export default function Checkout (props) {
  useOnStatusTapToPageTop({ offset: -16 })
  usePageDataInit()
  useRedirect()
  useOpenLoginDrawer()
  useRefreshCustomerInfo()
  useSendCheckoutEvent()

  const { isCart } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { datetime } = useParams()
  const { date, time, isImmediate } = datetime
  const { deliveryType, isStoreDelivery, isTable } = useDeliveryType()
  const testDate = useSelector(state => state.app.test.date)
  const merchant = useMerchant()
  const address = useSelector(state => state.user.address)
  const selectedOrder = useSelectedOrder()
  const selectedBatch = useSelectedBatch()
  const { total: batchTotal, originalTotal: originalBatchTotal } = libs.order.calculateDisplayBatchTotal(selectedBatch, selectedOrder) // 未送出的訂單需要用本地 batch 的金額判斷有沒有改變
  const isLogin = useIsLogin()
  const isSubmitting = useSelector(state => state.app.loadings.submit)
  const showPayment = !isTable || merchant?.setting?.payFirst

  // * 需要重新計算訂單並自動填入 promocode 的情形：
  // * batch 金額有變
  // * deliveryType 有變
  useEffect(() => {
    if (!selectedOrder) return // 沒有 selectedOrder 不處理
    if (!merchant?.id) return // 還沒有抓到 merchant 先不處理，等抓到再算
    dispatch(actions.order.calculateOrderForCheckout())
  }, [deliveryType, batchTotal, originalBatchTotal, merchant?.id, isLogin])

  // * 1. deliveryType 改變時需重新計算 shipping，TABLE 和 TAKEAWAY 會在 createShipping 時重設為 0
  // * 2. 時間或地址（date, time, isImmediate, addres）有變需重新計算運費
  // * 3. 測試日期（testDate）有變需重新計算運費（盆菜活動測試時會變更測試日期，根據下單日期不同會有不同的運費）
  // * submit 中則不重新計算
  useEffect(() => {
    if (isSubmitting) return
    if (!address) return
    dispatch(actions.order.createShipping())
  }, [date, time, isImmediate, address, deliveryType, testDate, isSubmitting])

  /**
   * 提醒客人下單之前檢查訂單內容，僅於堂食後付款時顯示
   * @returns
   */
  function RecheckItemsNoticeCard () {
    const isTable = selectedOrder?.deliveryType === 'table'
    const isPayfirst = merchant?.setting?.payFirst
    const shouldDisplay = isTable && !isPayfirst // 堂食後付款
    const message = t('app.page.checkout.recheck_items_notice.message')
    if (!shouldDisplay) return null
    return <NoticeCard variant='info' message={message} />
  }
  logger.log('[DOMLoading] Checkout')
  logger.log('[DOMLoading] [debug] ', { selectedOrder, 'merchant.setting.enable2c2p': merchant.setting.enable2c2p, 'window.My2c2p': window.My2c2p })

  if (!selectedOrder) return null
  // FIXME: wait 2c2p fix their sdk
  // if (merchant.setting.enable2c2p && !window.My2c2p) {
  //   logger.error('未成功載入 2c2p sdk')
  //   return null // 未成功載入 2c2p sdk
  // }

  if (isCart) {
    return (
      <Page>
        <Navbar
          title={t('app.page.checkout.navbar.cart')}
          onBack={() => dispatch(actions.app.toggleDrawer('cart'))}
        />
        <PageContainer className={classes.container}>
          <InfoCard isCart />
          <OrderBatchItemList order={selectedOrder} batches={[selectedBatch]} editable />
          <OrderRewardItemList order={selectedOrder} editable />
          <PaymentSummaryCard order={selectedOrder} />
        </PageContainer>
        <CartFooter />
      </Page>
    )
  }

  return (
    <BatchItemQuantityContextProvider>
      <Page>
        <Navbar title={t('app.page.checkout.navbar.title')} />
        <PageContainer className={classes.container}>
          <RecommendedMenuList />
          <InfoCard />
          <RecheckItemsNoticeCard />
          <OrderBatchItemList order={selectedOrder} batches={[selectedBatch]} editable />
          <OrderRewardItemList order={selectedOrder} editable />
          <RedeemRiceCoinCard />
          {isStoreDelivery && <DonateRiceCoinCard />}
          {showPayment && <PaymentSummaryCard order={selectedOrder} batches={[selectedBatch]} stepper />}
          {showPayment && <PaymentCard order={selectedOrder} />}
          <TermsAndConditions />
        </PageContainer>
        <CheckoutFooter />
        <BackdropLoading open={isSubmitting} />
        <PaymentMethodDrawer />
      </Page>
    </BatchItemQuantityContextProvider>
  )
}

/**
 * Coupons, Dimbox, PrioritizedPromoCodes
 */
function usePageDataInit () {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.user.getCoupons())
    dispatch(actions.dimbox.updateDimboxes())
    dispatch(actions.order.updatePrioritizedPromoCodes([]))
    return () => {
      dispatch(actions.order.updatePrioritizedPromoCodes([])) // 離開頁面時重設 prioritizedPromoCodes
    }
  }, [])
}

/**
 * 1. 當清空購物車時回到餐廳頁面
 * 2. 當沒有選擇 order 時
 *  a. D2C模式回到 /d2c/:id
 *  b. 平台模式回到 /restaurants
 */
function useRedirect () {
  const history = useHistory()
  const d2cBaseUrl = useD2CBaseUrl()
  const { isD2CWeb } = useParams()
  const selectedOrder = useSelectedOrder()
  const { hasItemsInCart } = useCart()

  useEffect(() => {
    // 當清空購物車時回到餐廳頁面
    if (!hasItemsInCart) {
      history.goBack()
    }
  }, [hasItemsInCart])

  useEffect(() => {
    if (!selectedOrder) {
      // 當沒有選擇 order 時
      if (isD2CWeb) {
        // D2C模式回到 /d2c/:id
        history.replace(d2cBaseUrl)
      } else {
        // 平台模式回到 /restaurants
        history.replace('/restaurants')
      }
    }
  }, [selectedOrder, isD2CWeb])
}

/**
 * 沒登入且非內用的話打開登入 Drawer (但是在 web 中且是內狀態時不用打開登入 Drawer)
 */
function useOpenLoginDrawer () {
  const dispatch = useDispatch()
  const selectedOrder = useSelectedOrder()
  const isLogin = useIsLogin()
  const { isTable } = useDeliveryType()

  useEffect(() => {
    if (selectedOrder && !isLogin && !(!Capacitor.isNativePlatform() && isTable)) {
      dispatch(actions.app.toggleLoginDrawer(true))
    }
  }, [isLogin])
}

/**
 * update recent card payment information
 * 只在沒有 selectedPaymentMethod 時更新
 * 以防止用戶新增信用卡後返回 checkout page 的時候洗掉剛輸入了的卡
 */
function useRefreshCustomerInfo () {
  const dispatch = useDispatch()
  const { selectedPaymentMethod } = useSelector(state => state.payment)
  const savePayment = useSelector(state => state.payment.savePayment)

  useEffect(() => {
    if (!selectedPaymentMethod && savePayment) {
      dispatch(actions.user.getCustomerInfo())
    }
  }, [dispatch, selectedPaymentMethod, savePayment])
}

function useSendCheckoutEvent () {
  const { sendEvent } = useTrack()
  const merchant = useMerchant()
  const { batchItems } = useCart()
  const selectedOrder = useSelectedOrder()

  const debouncedSendCheckoutEvent = useMemo(
    () => _.debounce((items, order) => {
      sendEvent(constants.track.EVENT.BEGIN_CHECKOUT, { items, order, merchant })
    }, 2000),
    [],
  )

  useEffect(() => {
    debouncedSendCheckoutEvent(batchItems, selectedOrder)
  }, [selectedOrder?.modifiers, batchItems])
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: constants.app.marginTop.NAVBAR,
    padding: theme.spacing(2),
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& > :last-child': {
      marginBottom: props => props.isCart
        ? theme.spacing(20)
        : theme.spacing(15),
    },
  },
}))
